import * as Sentry from "@sentry/browser";

$(window).bind('turbo:load', function () {
  Sentry.init({
    dsn: window.SENTRY__PUBLIC_DSN,
    release: window.SENTRY_RELEASE,
    integrations: [Sentry.browserTracingIntegration()],
    environment: window.SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
  });
});
